export const formField = [
  {
    label: '款式',
    prop: 'structName',
    name: 'structName',
    colNum: 5,
    placeholder: '请输入款式'
  },
  {
    label: '原型名称',
    prop: 'name',
    name: 'name',
    colNum: 5,
    placeholder: '请输入原型名称'
  },
  {
    label: '原型状态',
    prop: 'status',
    name: 'status',
    colNum: 5,
    placeholder: '请选择原型状态',
    slotName: 'shelvesSlot'
  },
  {
    colNum: 1,
    slotName: 'operation'
  }
]
